import React, { 
  useEffect,
  useContext,
  useRef,
  useState,
  Fragment,
} from "react";
import {
  StyledVimeoPlayer,
  StyledVimeoPlayerThumb,
  StyledVimeoPlayerThumbGatsby,
  StyledVimeoPlayerVideo,
  StyledVimeoControllersOnVideo,
  StyledVimeoControllersOnVideoCenter,
  StyledVimeoController,
  StyledVimeoButtonsContainer,
} from "./style.jsx";
import Player from "@vimeo/player";
import { StyledVimeoPlayerVideoPlayIcon } from "./style";
import Icon, { icons } from "../Icon/Icon";
import { DataContext } from "../../contexts/DataContext";
import { StaticImage } from "gatsby-plugin-image";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

export default ({
  videoID,
  thumb,
  play,
  playsInLine = false,
  showIcon,
  videoWidth,
  videoLeft,
  height,
  setPlay,
  autoPlay = false,
  muted = false,
  loop = false,
  playControl = true,
  controlCenter = false,
}) => {
  const playerEl = useRef();
  const [player, setPlayer] = useState(null);
  const [isMuted, setIsMuted] = useState(muted);
  const [hasThumb, setHasThumb] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const { languages, pageImages } = useContext(DataContext);
  const currentLanguage = languages.filter((language) => language.isCurrent);
  const currentLanguageCode = currentLanguage ? currentLanguage[0].label : null;
  const imageData = pageImages.find((e) => e.path === thumb);

  useEffect(() => {
    if (player) {
      player.loadVideo(videoID);
    }
  }, [videoID]);

  useEffect(() => {
    if (play && videoID && player === null) {
      setHasThumb(false);
      console.log("InitPlayer");
      const playerTemp = new Player(playerEl.current, {
        id: videoID,
        responsive: true,
        autoplay: false,
        volume: 0.35,
        playsinline: playsInLine,
        muted: isMuted,
        controls: false,
        autopause: true,
        background: false,
        loop: loop,
      });
      playerTemp.play();
      playerTemp.on("loaded", () => {
        setHasThumb(false);
        setIsLoaded(true);
        if(autoPlay) {
          setPlay(true);
        }
      });

      playerTemp.on("ended", () => {
        console.log("Endeed");
        setHasThumb(true);
        setPlay(false);
        playerTemp.setCurrentTime(0);
      });

      if (currentLanguageCode) {
        // console.log(`loading ${currentLanguageCode} subtitles...`)
        playerTemp
          .enableTextTrack(currentLanguageCode)
          .then(function (track) {
            // console.log(`${currentLanguageCode} subtitles loaded.`)
          })
          .catch(function (error) {
            console.log("Subtitles not loaded:", error);
          });
      }
      setPlayer(playerTemp);
    }

    if (player && play === true) {
      console.log("Play True & player truee");
      setHasThumb(false);
      player.play();
    }

    if (play === false && player) {
      console.log("Play False & player truee");
      player.pause();
    }
  }, [play]);

  return (
    <StyledVimeoPlayer>
      {typeof thumb === "string" ? (
        <>
          {imageData ? (
            <StyledVimeoPlayerThumbGatsby isVisible={hasThumb || (autoPlay ? (!isLoaded || !play) : !play)}>
              <GatsbyImage
                style={{ height: height, borderRadius: 10 }}
                image={getImage(imageData)}
                alt={imageData.alt}
              />
            </StyledVimeoPlayerThumbGatsby>
          ) : (
            <StyledVimeoPlayerThumb src={thumb} isVisible={hasThumb || (autoPlay ? (!isLoaded || !play) : !play)} />
          )}
        </>
      ) : (
        <StyledVimeoPlayerThumb as="picture" isVisible={hasThumb || (autoPlay ? (!isLoaded || !play) : !play)}>
          <StaticImage
            loading="eager"
            style={{ borderRadius: "10px" }}
            layout="fullWidth"
            src="../../../static/2020/images/home/video-thumb-desk.jpg"
            alt="Api e Alveri 3Bee"
          />
        </StyledVimeoPlayerThumb>
      )}
      {showIcon !== false && videoID && (
        <StyledVimeoPlayerVideoPlayIcon
          showIcon={hasThumb || (autoPlay ? (!isLoaded || !play) : !play)}
          onClick={() => setPlay(true)}
        >
          <Icon icon={icons.play2} color="white" />
        </StyledVimeoPlayerVideoPlayIcon>
      )}
      {videoID && (
        <div>
          <StyledVimeoPlayerVideo
            display={play}
            width={videoWidth}
            left={videoLeft}
            height={height}
            ref={playerEl}
          />
          {
            controlCenter
              ? <StyledVimeoControllersOnVideoCenter>
                <StyledVimeoButtonsContainer>
                  <StyledVimeoController display={play && playControl} onClick={() => setPlay(false)}>
                    <Icon icon={icons.stop} color="white" />
                  </StyledVimeoController>
                  <StyledVimeoController display={play && !isMuted}
                    onClick={() => {
                      player.setMuted(true);
                      setIsMuted(true);
                    }}
                  >
                    <Icon icon={icons.audio} color="white" />
                  </StyledVimeoController>
                  <StyledVimeoController
                    display={play && isMuted}
                    onClick={() => {
                      player.setMuted(false);
                      setIsMuted(false);
                    }}
                  >
                    <Icon icon={icons.audioOff} color="white" />
                  </StyledVimeoController>
                </StyledVimeoButtonsContainer>
              </StyledVimeoControllersOnVideoCenter>
              : <Fragment>
                <StyledVimeoControllersOnVideo
                  display={play && playControl}
                  top="5%"
                  left="5%"
                  onClick={() => setPlay(false)}
                >
                  <Icon icon={icons.stop} color="white" />
                </StyledVimeoControllersOnVideo>

                <StyledVimeoControllersOnVideo
                  display={play && !isMuted}
                  bottom="5%"
                  left="5%"
                  onClick={() => {
                    player.setMuted(true);
                    setIsMuted(true);
                  }}
                >
                  <Icon icon={icons.audio} color="white" />
                </StyledVimeoControllersOnVideo>

                <StyledVimeoControllersOnVideo
                  display={play && isMuted}
                  bottom="5%"
                  left="5%"
                  onClick={() => {
                    player.setMuted(false);
                    setIsMuted(false);
                  }}
                >
                  <Icon icon={icons.audioOff} color="white" />
                </StyledVimeoControllersOnVideo>
              </Fragment>
          }
        </div>
      )}
    </StyledVimeoPlayer>
  );
};
