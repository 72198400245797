import styled, { css } from "styled-components";
import { BP, Rem } from "../../commons/Theme";
import { StyledIcon } from "../Icon/style";

export const StyledVimeoPlayer = styled.div`
  position: relative;
  width: 100%;
  z-index: 1;
  background-color: inherit;

`;

export const StyledVimeoPlayerVideo = styled.div`
  position: absolute;
  top: 50%;
  left: ${(props) => (props.left - 20) || (props.width ? (100 - props.width) / 2 : "0")}%;
  width: ${(props) => props.width ? props.width : "100"}%;
  height: ${(props) => props.height ?? "auto"};
  display: ${(props) => props.display ? "block" : "none"};
  transform: translateY(-50%);
  z-index: 1;

  @media (${BP.ipad}) {
    left: ${(props) => props.left || (props.width ? (100 - props.width) / 2 : "0")}%;
  }
`;

export const StyledVimeoPlayerVideoPlayIcon = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
  cursor: pointer;

  ${StyledIcon} {
    width: ${Rem(60)};
    transition: transform 0.2s linear 0s;
  }

  ${(props) =>
    !props.showIcon
    && css`
      display: none;
    `}
`;

export const StyledVimeoControllersOnVideo = styled.div`
  position: absolute;
  top: ${(props) => props.top ? props.top : "auto"};
  bottom: ${(props) => props.bottom? props.bottom : "auto"};
  left: ${(props) => props.left ? props.left : "50px"};
  z-index: 3;
  cursor: pointer;
  display: ${(props) => props.display ? "block" : "none"};
  width: 10%;  
  max-width: 60px;
  ${StyledIcon} {
    width: 100%;
    transition: transform 0.2s linear 0s;
  }
`;

export const StyledVimeoControllersOnVideoCenter = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  width: 100%;
  height: 100%;
  opacity: 0;

  &:hover {
    opacity: 1;
  }
`;

export const StyledVimeoButtonsContainer = styled.div`
  padding-top: 40%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  padding-left: 0rem;

  @media (${BP.ipad}) {
    padding-left: 6rem;
  }
`;

export const StyledVimeoController = styled.div`
  position: relative;
  cursor: pointer;
  display: ${(props) => props.display ? "block" : "none"};  
  max-width: 60px;
  ${StyledIcon} {
    width: 100%;
    transition: transform 0.2s linear 0s;
  }
`;

export const StyledVimeoPlayerThumb = styled.img`
  position: relative;
  width: 100%;
  height: auto;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.25s ease-out 0s;
  z-index: 3;
  border-radius: 10px;

  &:hover {
    ~ * ${StyledIcon} {
      transform: scale(1.25);
    }
  }

  > img {
    width: 100%;
  }

  ${(props) =>
    props.isVisible
    && css`
      opacity: 1;
      pointer-events: all;
    `}
`;

export const StyledVimeoPlayerThumbGatsby = styled.div`
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.25s ease-out 0s;

  &:hover {
    ~ * ${StyledIcon} {
      transform: scale(1.25);
    }
  }

  ${(props) =>
    props.isVisible
    && css`
      opacity: 1;
      pointer-events: all;
    `}
`;
